import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
	Navigate
} from "react-router-dom";

import AuthProvider from './auth/AuthProvider';
import RequireAuth from './auth/RequireAuth';
import RequireAccess from './auth/RequireAccess';

import Login from './containers/Login';
import Logout from './containers/Logout';
import AuthComplete from './containers/AuthComplete';
import AuthFailure from './containers/AuthFailure';

import SelectBranch from './containers/SelectBranch';

import SystemApp from './containers/SystemApp';
import BranchApp from './containers/BranchApp';

import './App.css';

import Setup from './containers/Setup';
import AuthTwoFactor from './containers/AuthTwoFactor';
import RedirectRoutes from './containers/RedirectRoutes';
import AuthResetPassword from './containers/AuthResetPassword';
import AuthInvite from './containers/AuthInvite';

const AppRoot = () => {
	return (
		<Navigate to="/auth/selectbranch" />
	);
};

const App = () => {
	let [setupStatus, setSetupStatus] = React.useState(null);

	const loadSetupStatus = () => {
		fetch('/api/setup/status')
		.then(res => res.json())
		.then(json => {
			setSetupStatus(json);
		});
	};

	React.useEffect(() => {
		loadSetupStatus();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App">
			<Router>
				<AuthProvider>
					{
						setupStatus && (
							setupStatus.isConfigured ? (
								<Routes>
									<Route path="/" element={<AppRoot />} />
									<Route path="/auth" element={<Navigate to="/auth/login" />} />
									<Route path="/auth/login" element={<Login />} />
									<Route path="/auth/logout" element={<Logout />} />
									<Route path="/auth/resetpassword" element={<AuthResetPassword />} />
									<Route path="/auth/invite" element={<AuthInvite />} />
									<Route path="/auth/verify" element={<AuthTwoFactor />} />
									<Route path="/auth/complete" element={<AuthComplete />} />
									<Route path="/auth/failure" element={<AuthFailure />} />
									<Route path="/auth/selectbranch" element={<RequireAuth><SelectBranch /></RequireAuth>} />
									<Route path="/system/*" element={<RequireAuth><RequireAccess branch="system"><SystemApp /></RequireAccess></RequireAuth>} />
									<Route path="/:branchID/*" element={<RequireAuth><RequireAccess><BranchApp /></RequireAccess></RequireAuth>} />
									<Route path="/redirect/*" element={<RedirectRoutes />} />
								</Routes>
							) : (
								<Routes>
									<Route path="/setup" element={<Setup onComplete={loadSetupStatus} />} />
									<Route path="/*" element={<Navigate to="/setup" />} />
								</Routes>
							)
						)
					}
				</AuthProvider>
			</Router>
		</div>
	);
};

export default App;
