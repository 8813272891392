import React from 'react';

export function useQueryParamState(field) {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const value = urlParams.get(field);

	if (value) {
		let url = new URL(window.location.href);
		url.searchParams.delete(field);
		window.history.replaceState({}, document.title, url.toString());
	}

	return React.useState(value);
}