import React from 'react';
import PageSection from '../../../components/PageSection';
import DataTable from '../../../components/DataTable';
import { Link } from 'react-router-dom';
import { useAPI } from '../../../api';
import Avatar from '../../../components/Avatar';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

const SystemUsersList = ({ users, onChanged }) => {
	let api = useAPI();

	let [userBatchFile, setUserBatchFile] = React.useState(null);

	const batchCreateUsers = () => {
		if (!userBatchFile) {
			return;
		}

		// Read the file
		let reader = new FileReader();

		reader.onload = (e) => {
			let text = e.target.result;
			let lines = text.split('\n').map(line => line.trim()).filter(line => line);

			let users = lines.map(line => {
				let parts = line.split(',');

				return {
					username: parts[0],
					display: parts[1],
					password: parts[2]
				};
			});

			api.post('/api/system/users', users)
			.then(json => onChanged());
		};

		reader.readAsText(userBatchFile);
	};

	const deleteUser = (user) => {
		if (window.confirm(`Delete user ${user.username}?`)) {
			api.delete(`/api/system/users/${user.id}`)
			.then(json => onChanged());
		}
	};

	const resetAllAvatars = () => {
		if (window.confirm('Reset all avatars?')) {
			api.post('/api/system/users/all/avatar/reset')
			.then(json => {
				onChanged();
				window.location.reload();
			});
		}
	}

	return (
		<div>
			<PageSection title="Users" level={2}>
				<DataTable
					data={users}
					columns={[
						{ label: '', key: 'avatar', render: (user) => <Avatar src={`/api/users/${user.id}/avatar?size=small`} alt="" size="24px" /> },
						{ label: 'Username', key: 'username', render: (user) => <Link to={"/system/users/" + user.id}>{user.username}</Link> },
						{ label: 'Display Name', key: 'display' },
						{ label: 'ID', key: 'id' },
						{ label: 'Status', key: 'status' },
						{ label: 'Access Grants', key: 'accessGrants', render: (user) => user.access.map((a, i) => <span key={i}>{`${a.branch}/${a.accessLevel}`}<br /></span>) },
						{ label: 'Strategies', key: 'auth.strategies' },
						{ label: 'Last Strategy', key: 'auth.lastStrategy' },
						{ label: '2FA', key: 'auth.twoFactor.enabled', render: (user) => user.auth.twoFactor.enabled ? 'Enabled' : 'Disabled' },
					]}
					tools={[
						{ label: 'Delete', action: (user) => deleteUser(user) }
					]}
					tableTools={[
						{ label: 'Create New User...', render: () => <Link to="/system/users/new">Create New User...</Link> },
						{ label: 'Reset All Avatars', action: resetAllAvatars }
					]}
				/>
			</PageSection>

			<PageSection title="Batch Create Users" level={3}>
				<p>
					You can batch create users by uploading a CSV file with the following columns:
				</p>
				
				<ul>
					<li>username/email</li>
					<li>display</li>
					<li>password (optional - will enable 'local' auth)</li>
				</ul>

				<input type="file" name="file" onChange={e => setUserBatchFile(e.target.files[0])} />
				<button onClick={batchCreateUsers}>Upload and Create Users</button>
			</PageSection>

			<PageSection title="Raw Data" level={3}>
				<RawJsonDisplay data={users} />
			</PageSection>
		</div>
	);
}

export default SystemUsersList;