import React from 'react';
import { getPropByPath, setPropByPath } from '../../helpers';

const PropEditorSelectOptionNullSpecialValue = '____null____';

function secretProp(prop, secretsProp, knownSecretsProp) {
	secretsProp ||= 'secrets';
	knownSecretsProp ||= 'knownSecrets';

	return {
		...prop,
		render: (o, update) => {
			if (!o[knownSecretsProp]?.includes(prop.key) || o[secretsProp]?.[prop.key] !== undefined)
				return false;

			return <div><code>********</code> <input type="button" value={`Change ${prop.label}`} onClick={
				() => { update({ ...o, [secretsProp]: { ...o.secrets, [prop.key]: '' }}); }
			} /></div>;
		}
	};
};

const PropEditor = ({ object, props, tools, onChange }) => {
	if (!props) {
		if (object) props = Object.getOwnPropertyNames(object).map(key => ({ key, label: key[0].toUpperCase() + key.slice(1) }));
		else props = [];
	}

	if (tools) {
		tools = tools.map(tool => {
			if (typeof tool === 'function')
				return { render: tool };
			else if (tool.action && !tool.render)
				return { ...tool, render: (item) => (<button type="button" onClick={() => tool.action(item)}>{tool.label}</button>) };
			else
				return tool;
		});
	};

	const getPropValue = (object, prop) => {
		if (prop.value) {
			if (typeof prop.value === 'function')
				return prop.value(object);
			else
				return prop.value;
		}

		if (!prop.context)
			return getPropByPath(object, prop.key);

		if (typeof prop.context === 'function')
			return getPropByPath(prop.context(object), prop.key) ?? prop.defaultValue;

		if (typeof prop.context === 'string')
			return getPropByPath(object[prop.context], prop.key) ?? prop.defaultValue;

		throw new Error(`Invalid context type: ${typeof context}`);
	};

	const getMultiSelectValue = (select) => {
		let options = select.options;
		let value = [];

		for (let i = 0; i < options.length; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}
		
		return value;
	};

	const getLocalTime = (date) => {
		let local = new Date(date);
		local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
		return local;
	};

	const generateUpdatedObject = (object, context, key, value) => {
		if (value === PropEditorSelectOptionNullSpecialValue)
			value = null;

		if (!context) {
			let result = { ...object };
			setPropByPath(result, key, value);
			return result;
		}

		if (typeof context === 'function') {
			// Deep clone object
			let clonedObject = JSON.parse(JSON.stringify(object));
			let clonedContext = context(clonedObject);

			setPropByPath(clonedContext, key, value);
			
			return clonedObject;
		}

		if (typeof context === 'string') {
			// Don't need to deep clone when context is a string as it can only be a first level property
			let result = { ...object, [context]: { ...object[context] } };
			let resultContext = result[context];

			setPropByPath(resultContext, key, value);

			return result;
		}

		throw new Error(`Invalid context type: ${typeof context}`);
	};

	const readFileToProp = (object, context, key, file) => {
		let reader = new FileReader();

		reader.onload = (e) => {
			onChange(generateUpdatedObject(object, context, key, e.target.result));
		};

		reader.readAsText(file);
	};

	if (!object) object = {};

	return (
		<form autoComplete="off">
			<table>
				<tbody>
					{
						props.map((prop, propIndex) => (
							<tr key={prop.key + `_${propIndex}`}>
								<td colSpan={prop.type && prop.type === 'info' ? 2 : 1}><span style={{ fontWeight: prop.type && prop.type === 'info' ? 'bold' : 'normal'}}>{prop.label}</span></td>
								<td>
									{
										(prop.type && prop.type === 'info' && <div></div>)
										||
										(prop.render && prop.render(object, onChange))
										||
										(
											prop.type && prop.type === 'select'
											? (
												<select disabled={prop.disabled} value={getPropValue(object, prop) ?? prop.defaultValue ?? ''} onChange={e => onChange(generateUpdatedObject(object, prop.context, prop.key, e.target.value))}>
													{
														prop.options && prop.options.map(option => 
															option && (
																typeof option === 'string'
																? (<option key={option} value={option}>{option}</option>)
																: (<option key={option.value ?? PropEditorSelectOptionNullSpecialValue} value={option.value ?? PropEditorSelectOptionNullSpecialValue}>{option.label}</option>)
															)
														)
													}
												</select>
											)
											: prop.type && prop.type === 'multiselect'
											? (
												<select disabled={prop.disabled} value={getPropValue(object, prop) ?? prop.defaultValue ?? []} onChange={e => onChange(generateUpdatedObject(object, prop.context, prop.key, getMultiSelectValue(e.target)))} multiple>
													{
														prop.options && prop.options.map(option => 
															option && (
																typeof option === 'string'
																? (<option key={option} value={option}>{option}</option>)
																: (<option key={option.value} value={option.value}>{option.label}</option>)
															)
														)
													}
												</select>
											)
											: prop.type && prop.type === 'datetime'
											? (
												<input
													type="datetime-local"
													disabled={prop.disabled}
													value={getLocalTime(new Date(prop.present?.(getPropValue(object, prop)) ?? getPropValue(object, prop) ?? prop.defaultValue ?? '')).toISOString().slice(0, -8)}
													onChange={e => onChange(generateUpdatedObject(object, prop.context, prop.key, e.target.value))}
												/>
											)
											: prop.type && prop.type === 'file'
											? (
												<input
													type="file"
													disabled={prop.disabled}
													accept={prop.accept || '*'}
													onChange={e => readFileToProp(object, prop.context, prop.key, e.target.files[0])}
												/>
											)
											: prop.type && prop.type === 'checkbox'
											? (
												<input
													type="checkbox"
													disabled={prop.disabled}
													checked={getPropValue(object, prop) ?? prop.defaultValue ?? false}
													onChange={e => onChange(generateUpdatedObject(object, prop.context, prop.key, e.target.checked))}
												/>
											)
											: (
												<input
													type={prop.type || "text"}
													disabled={prop.disabled}
													size={prop.size || null}
													placeholder={prop.placeholder || null}
													value={prop.present?.(getPropValue(object, prop)) ?? getPropValue(object, prop) ?? prop.defaultValue ?? ''}
													onChange={e => onChange(generateUpdatedObject(object, prop.context, prop.key, prop.parse?.(e.target.value) ?? e.target.value))}
												/>
											)
										)
									}

									{
										prop.help && (
											<div style={{ fontSize: '0.8em', color: '#aaa' }}>
												{prop.help}
											</div>
										)
									}
								</td>
							</tr>
						))
					}
					
					{
						tools && (
							<tr>
								<td colSpan={2}>
									{
										tools.map((tool, toolIndex) => (
											<React.Fragment key={`t${toolIndex}`}>
												{tool.render()}
											</React.Fragment>
										))
									}
								</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</form>
	);
}

export default PropEditor;
export { secretProp };