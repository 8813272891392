import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAPI } from '../../api';
import { useAuth } from '../../auth/AuthProvider';
import { useQueryParamState } from '../../helpers/query';

const Login = () => {
	const api = useAPI();
	const auth = useAuth();

	const [loginError, setLoginError] = useQueryParamState('error');

  let location = useLocation();

	let from = location.state?.from?.pathname;

	if (from) localStorage.setItem('afterlogintarget', from);

	const reauth = !!location.state?.reauth;

	let [username, setUsername] = useState(reauth ? auth.user : null);
	let [showAllStrategies, setShowAllStrategies] = useState(false);
	let [authStrategies, setAuthStrategies] = useState(null);
	let [selectedStrategy, setSelectedStrategy] = useState(null);

	const loadAuthStrategies = (username) => {
		api.post('/api/auth/strategies', { username })
		.then(json => {
			setAuthStrategies(json);
			
			if (json.last) setSelectedStrategy(json.last);
		});
	};

	React.useEffect(() => {
		if (reauth && username)
			loadAuthStrategies(username);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function setUsernameClick(e) {
		e.preventDefault();

		let username = e.target.elements.username.value;

		setUsername(username);
		setLoginError(null);

		loadAuthStrategies(username);
	};

	function changeUsername(e) {
		e.preventDefault();

		setUsername(null);
		setAuthStrategies(null);
		setLoginError(null);
	};

	function forgotPassword(e) {
		e.preventDefault();

		api.post('/api/auth/recovery/resetpassword', { email: username })
		.then(() => {
			setLoginError('Password reset email sent.');
		})
	};

	function renderStrategySelector(strategy) {
		return (
			<div key={strategy}>
				<button onClick={() => setSelectedStrategy(strategy)}>{
					{
						'local': 'Use Username/Email/Password',
						'google': 'Login with Google OAuth',
						'saml': 'Login with SAML',
						'ldap': 'Login with LDAP'
					}[strategy]
				} ({strategy})</button>
			</div>
		);
	};

	function renderStrategyUI(strategy) {
		let strategyElements = (<div></div>);

		switch (strategy) {
			case 'local': {
				strategyElements = (
					<div>
						<h4>{strategy}</h4>

						<form method="POST" action={`/api/auth/login/local`}>
							<input type="hidden" name="username" value={username} />

							<div className="spacedFormGroup">
								<label>Password</label>
								<input type="password" name="password" autoFocus={true} />
							</div>

							<div className="spacedFormGroup">
								<button type="submit">Login</button>
							</div>

							<div className="spacedFormGroup">
								<button onClick={forgotPassword}>Forgot Password</button>
							</div>
						</form>
					</div>
				);

				break;
			}
			case 'google': {
				strategyElements = (
					<div>
						<form method="POST" action={`/api/auth/login/google`}>
							<input type="hidden" name="username" value={username} />

							<div className="spacedFormGroup">
								<button type="submit">Login with Google OAuth</button>
							</div>
						</form>
					</div>
				);
				break;
			}
			case 'saml': {
				strategyElements = (
					<div>
						<form method="POST" action={`/api/auth/login/saml`}>
							<input type="hidden" name="username" value={username} />

							<div className="spacedFormGroup">
								<button type="submit">Login with SAML</button>
							</div>
						</form>
					</div>
				);
				break;
			}
			case 'ldap': {
				strategyElements = (
					<div>
						<form method="POST" action={`/api/auth/login/ldap`}>
							<input type="hidden" name="username" value={username} />

							<div className="spacedFormGroup">
								<label>Password</label>
								<input type="password" name="password" autoFocus={true} />
							</div>

							<div className="spacedFormGroup">
								<button type="submit">Login with LDAP</button>
							</div>
						</form>
					</div>
				);
				break;
			}
			default: {
				strategyElements = (<div key={strategy}>Unrecognised auth strategy '{strategy}'</div>);
				break;
			}
		}

		return strategyElements;
	};

	return (
		<div className="centeredContainer">
			<div>
				<h1>Fastvue Platform Dev UI Login</h1>
				
				<div className="spacedFormGroup">
					Target URL:
					<span style={{fontFamily: 'monospace' }}>{from}</span>
				</div>

				{
					reauth &&
					(
						<div className="spacedFormGroup" style={{color: "yellow"}}>You need to re-authenticate to perform this action.</div>
					)
				}

				{
					!username &&
					(
						<div>
							<form onSubmit={setUsernameClick}>
								<div className="spacedFormGroup">
									Enter username or email<br />
									<input type="text" name="username" autoFocus={true} />
								</div>

								<div className="spacedFormGroup">
									<button type="submit">Continue &gt;&gt;</button>
								</div>
							</form>
						</div>
					)
				}

				{
					username && authStrategies && !selectedStrategy &&
					(
						<div>
							<div>
								Username: <span style={{fontFamily: 'monospace'}}>{username}</span> <button onClick={changeUsername}>(Change Username)</button>
							</div>

							<h3>Select a login strategy</h3>

							<hr />

							<div>
								<h5>User's known strategies</h5>
							</div>

							<div>
								{
									authStrategies && authStrategies.user && authStrategies.user.map(strategy => 
										<div key={strategy} style={{ marginTop: '1.5em' }}>{renderStrategySelector(strategy)}</div>
									)
								}

								{
									authStrategies && (!authStrategies.user || authStrategies.user.length === 0) &&
									(
										<div className="spacedFormGroup" style={{color: "yellow"}}>No known login methods configured for user.</div>
									)
								}
							</div>

							<div>
								<hr />
								<div>
									Or, show all available strategies:
								</div>

								{
									!showAllStrategies &&
									(
										<button onClick={() => setShowAllStrategies(true)}>Show All Strategies</button>
									)
								}

								{
									showAllStrategies &&
									(
										<button onClick={() => setShowAllStrategies(false)}>Hide All Strategies</button>
									)
								}
							</div>

							{
								showAllStrategies &&
								(
									<div>
										
										<hr />

										<div>
											<h5>All available strategies</h5>
										</div>

										<div>
											{
												authStrategies && authStrategies.available.map(strategy => 
													<div key={strategy} style={{ marginTop: '1.5em' }}>{renderStrategySelector(strategy)}</div>
												)
											}

											{
												authStrategies && authStrategies.available.length === 0 &&
												(
													<div className="spacedFormGroup" style={{color: "red"}}>No valid login methods.</div>
												)
											}
										</div>
									</div>
								)
							}
						</div>
					)
				}

				{
					username && authStrategies && selectedStrategy &&
					(
						<div>
							<div>
								Username: <span style={{fontFamily: 'monospace'}}>{username}</span> <button onClick={changeUsername}>(Change Username)</button>
							</div>

							<div>
								Strategy: <span style={{fontFamily: 'monospace'}}>{selectedStrategy}</span> <button onClick={() => setSelectedStrategy(null)}>(Change Strategy)</button>
							</div>

							<div>
								{renderStrategyUI(selectedStrategy)}
							</div>
						</div>
					)
				}

				{ loginError && (<div className="spacedFormGroup" style={{color: "red"}}>{loginError}</div>) }
			</div>
		</div>
	);
};

export default Login;