import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

function ParamNavigate({ to, params }) {
	let url = to;

	if (params.size > 0) {
		url += '?';

		params.forEach((value, key) => {
			url += `${key}=${encodeURIComponent(value)}&`;
		});
	}

	return <Navigate to={url} />;
}

export default function RedirectRoutes() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	return (
		<Routes>
			{[
				{ path: '/emailsettings/*', redirect: '/system/email' },
				'/auth/login',
				'/auth/logout',
				'/auth/complete',
				'/auth/verify',
				{ path: '/auth/failure', redirect: '/auth/login' },
				'/auth/resetpassword',
				'/auth/invite'
			].map((redirect) => {
				if (typeof redirect === 'string') {
					return <Route key={redirect} path={redirect} element={<ParamNavigate to={redirect} params={urlParams} />} />;
				} else {
					return <Route key={redirect.path} path={redirect.path} element={<ParamNavigate to={redirect.redirect} params={urlParams} />} />;
				}
			})}
		</Routes>
	);
}