import React from 'react';

import { useAPI } from '../../api';
import { Navigate, useNavigate } from 'react-router-dom';

export default function AuthResetPassword() {
	const navigate = useNavigate();
	const api = useAPI();

	let searchParams = new URLSearchParams(window.location.search);
	const token = searchParams.get('token');

	let [error, setError] = React.useState(null);
	let [success, setSuccess] = React.useState(null);
	
	let [resetInfo, setResetInfo] = React.useState(null);
	let [code, setCode] = React.useState('');
	let [password, setPassword] = React.useState('');
	let [password2, setPassword2] = React.useState('');

	async function resetPassword(e) {
		if (password !== password2) {
			setError('Passwords do not match');
			return;
		}

		let json = await api.post(`/api/auth/recovery/resetpassword/${token}/reset`, { password });

		if (json.error) {
			setError(json.error);
		} else {
			setError(null);
			setSuccess('Password reset successfully! Redirecting to login page...');
			setTimeout(() => navigate('/auth/login'), 2000);
		}
	}

	async function verifyTwoFactor(e) {
		let json = await api.post(`/api/auth/recovery/resetpassword/${token}/verifytwofactor`, { code });

		if (json.error) {
			setError(json.error);
		} else {
			setError(null);
			setResetInfo(json);
		}
	}

	function loadResetInfo() {
		api.get(`/api/auth/recovery/resetpassword/${token}`)
		.then(json => {
			setResetInfo(json);
		});
	}

	React.useEffect(() => {
		loadResetInfo();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return token ? (
		<div className="centeredContainer">
			<div>
				<h1>Reset Password</h1>

				{resetInfo && (
					<div>
						{error ? <div style={{color: "red"}}>{error}</div> : null}
						{success ? <div style={{color: "green"}}>{success}</div> : null}

						{
							resetInfo.twoFactor.required && !resetInfo.twoFactor.verified ? (
								<div>
									<div className="spacedFormGroup">
										<label>Two Factor Code</label>
										<input type="text" name="code" value={code} onChange={e => setCode(e.target.value)} />
									</div>

									<div className="spacedFormGroup">
										<button onClick={verifyTwoFactor}>Verify Two Factor Code</button>
									</div>
								</div>
							) : ( 
								<div>
									<div className="spacedFormGroup">
										<div>
											<label>New Password</label>
											<input type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} />
										</div>

										<div>
											<label>Confirm Password</label>
											<input type="password" name="password2" value={password2} onChange={e => setPassword2(e.target.value)} />
										</div>
									</div>

									<div className="spacedFormGroup">
										<button onClick={resetPassword}>Reset Password</button>
									</div>
								</div>
							)
						}
					</div>
				)}
			</div>
		</div>
	) : <Navigate to="/auth/login" />;
}